import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BiHomeAlt } from "react-icons/bi";
import { TbBrandAsana , TbDiscount2} from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { SiApostrophe } from "react-icons/si";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FiShoppingCart } from "react-icons/fi";
import { TbUserCog } from "react-icons/tb";
import { GrClose } from "react-icons/gr";
import { BsImage } from "react-icons/bs";
import '../component/css/printstyle.css';
const Menu = (props) => {
  const location = useLocation();
  return (
    <div
      className={
        props.menutoggle == true
          ? "fixed left-[0%] bg-white md:bg-transparent md:relative md:left-0 md:block w-full h-full z-50 onprint"
          : "fixed left-[-100%] bg-white md:bg-transparent md:relative md:left-0 md:block w-full h-full onprint"
      }
    >
      <div className="flex justify-end px-5 py-5 md:hidden">
        <GrClose
          size={26}
          onClick={() => {
            props.setmenutoggle(false);
          }}
          className="cursor-pointer"
        />
      </div>
      <div className="w-full h-32 mb-20 flex justify-center items-center">
        NatureSelection
      </div>
      <div className="w-full pl-10">
        <div>Menu</div>
        <div className="mt-3">
          <div
            className={
              location.pathname == "/"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/"
              className={
                location.pathname == "/"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <BiHomeAlt size={21} /> <p className="pl-2">Dashboard</p>
            </Link>
          </div>
          <div
            className={
              location.pathname == "/brand"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/brand"
              className={
                location.pathname == "/brand"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <TbBrandAsana size={21} /> <p className="pl-2">Brand</p>
            </Link>
          </div>
          <div
            className={
              location.pathname == "/category"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/category"
              className={
                location.pathname == "/category"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <BiCategory size={21} /> <p className="pl-2">Category</p>
            </Link>
          </div>
          <div
            className={
              location.pathname == "/item"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/item"
              className={
                location.pathname == "/item"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <SiApostrophe size={21} /> <p className="pl-2">Item</p>
            </Link>
          </div>
          <div
            className={
              location.pathname == "/user"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/user"
              className={
                location.pathname == "/user"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <HiOutlineUserGroup size={21} /> <p className="pl-2">User</p>
            </Link>
          </div>
          <div
            className={
              location.pathname == "/order"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/order"
              className={
                location.pathname == "/order"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <FiShoppingCart size={21} /> <p className="pl-2">Order</p>
            </Link>
          </div>
          {/* <div
            className={
              location.pathname == "/report"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/report"
              className={
                location.pathname == "/report"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <MdOutlineAnalytics size={21} /> <p className="pl-2">Report</p>
            </Link>
          </div> */}
          <div
            className={
              location.pathname == "/cover"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/cover"
              className={
                location.pathname == "/cover"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <BsImage size={21} /> <p className="pl-2">Cover</p>
            </Link>
          </div>
          <div
            className={
              location.pathname == "/offer"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/offer"
              className={
                location.pathname == "/offer"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <TbDiscount2 size={21} /> <p className="pl-2">Offer</p>
            </Link>
          </div>
          <div
            className={
              location.pathname == "/admin"
                ? "pl-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pl-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/admin"
              className={
                location.pathname == "/admin"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {
                props.setmenutoggle(false);
              }}
            >
              <TbUserCog size={21} /> <p className="pl-2">Admin</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
