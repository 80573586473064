import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/provider";
import constant from "../../util/constant";
import numberbeautify from "../../util/numberbeautify";

const Userordermodal = props => {
  return (
    <>
    {props.show ? (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl uppercase font-semibold">
                  Order Detail
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => props.setshow(false)}
                >
                  <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                    X
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div className="flex flex-wrap w-full">
                  {/* <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto"> */}
                  <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Name / Brand
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Price
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Quantity
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Total Price
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.item.map((lis) => {
                          return (
                            <tr key={lis.item_id} className="cursor-pointer">
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex">
                                  <div className="ml-3">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {lis.item_name}
                                    </p>
                                    <p className="text-gray-600 whitespace-no-wrap">
                                      {lis.brand_name}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {numberbeautify(lis.item_price)}
                                </p>
                                <p className="text-gray-600 whitespace-no-wrap">
                                  USD
                                </p>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {lis.item_qty}
                                </p>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {numberbeautify(
                                    lis.item_price * lis.item_qty
                                  )}
                                </p>
                                <p className="text-gray-600 whitespace-no-wrap">
                                  USD
                                </p>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <span
                                  className={
                                    lis.item_state == "approve"
                                      ? "relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                                      : lis.item_state == "wait"
                                      ? "relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                                      : "relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"
                                  }
                                >
                                  <span
                                    aria-hidden
                                    className={
                                      lis.item_state == "approve"
                                        ? "absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                        : lis.item_state == "wait"
                                        ? "absolute inset-0 bg-orange-200 opacity-50 rounded-full"
                                        : "absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                    }
                                  ></span>
                                  <span className="relative">
                                    {lis.item_state}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                {/* <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    changestateaction(props.invoice_id, "reject")(recaller)(
                      setrecaller
                    );
                  }}
                >
                  Reject
                </button> */}
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setshow(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    ) : null}
  </>
  )
}

export default Userordermodal