import React,{useContext, useState} from 'react'
import { GlobalContext } from '../../context/provider';
import constant from '../../util/constant';
import Addbrandmodal from './addbrandmodal';
import Editbrandmodal from './editbrandmodal';

const Brand = () => {
  const {initState}=useContext(GlobalContext);

  const [brand_id,setbrand_id]=useState();
  const [brand_name,setbrand_name]=useState();
  const [brand_namear,setbrand_namear]=useState();
  const [brand_nameku,setbrand_nameku]=useState();
  const [brand_image,setbrand_image]=useState();
  const [show,setshow]=useState(false);
  const [show1,setshow1]=useState(false);
  return (
    <div className='onprint'>
        <Addbrandmodal show={show} setshow={setshow} />
        <Editbrandmodal show={show1} setshow={setshow1} brand_name={brand_name} setbrand_name={setbrand_name} brand_namear={brand_namear} setbrand_namear={setbrand_namear} brand_name_ku={brand_nameku} setbrand_nameku={setbrand_nameku} brand_image={brand_image} setbrand_image={setbrand_image} brand_id={brand_id} />
        <div className='w-full h-40 flex justify-center items-end font-extrabold text-2xl'>
            Brands
        </div>
        <div className="w-full px-10 py-10 flex justify-end">
          <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>setshow(true)}>Add Brand</button>
        </div>
      <div className='px-10 flex flex-wrap'>
        {initState.brand.map((lis)=>(
          <div className="max-w-xs rounded overflow-hidden shadow-lg mb-5 mx-2 cursor-pointer" onClick={()=>{
              setbrand_id(lis.brand_id);
              setbrand_name(lis.brand_name);
              setbrand_namear(lis.brand_name_ar);
              setbrand_nameku(lis.brand_name_ku);
              setbrand_image(lis.brand_image);
              setshow1(true);
          }} key={lis.brand_id}>
            <img className="w-96 h-60" src={constant.host+'uploads/'+lis.brand_image} alt={lis.brand_name} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{lis.brand_name}</div>
            </div>
            <div className="px-6 pt-4 pb-2">
              <span className={lis.brand_state=='active'?"inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-900 mr-2 mb-2":"inline-block bg-red-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-900 mr-2 mb-2"}>{lis.brand_state}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Brand