import React,{useState,useContext} from 'react';
import { GlobalContext } from '../../context/provider';
import constant from '../../util/constant';
import Addoffermodal from './addoffermodal';
import Editoffermodal from './editoffermodal';

const Offer = () => {
    const {initState}=useContext(GlobalContext);

    const [offer_id,setoffer_id]=useState();
    const [offer_name,setoffer_name]=useState();
    const [offer_name_ar,setoffer_name_ar]=useState();
    const [offer_name_ku,setoffer_name_ku]=useState();
    const [offer_description,setoffer_description]=useState();
    const [offer_description_ar,setoffer_description_ar]=useState();
    const [offer_description_ku,setoffer_description_ku]=useState();
    const [offer_image,setoffer_image]=useState();

    const [show,setshow]=useState(false);
    const [show1,setshow1]=useState(false);
  return (
    <div>
        <Addoffermodal show={show} setshow={setshow} />
        <Editoffermodal show={show1} setshow={setshow1} offer_id={offer_id} offer_name={offer_name} setoffer_name={setoffer_name} offer_name_ar={offer_name_ar} setoffer_name_ar={setoffer_name_ar} offer_name_ku={offer_name_ku} setoffer_name_ku={setoffer_name_ku} offer_description={offer_description} setoffer_description={setoffer_description} offer_description_ar={offer_description_ar} setoffer_description_ar={setoffer_description_ar} offer_description_ku={offer_description_ku} setoffer_description_ku={setoffer_description_ku} />
        <div className='w-full h-40 flex justify-center items-end font-extrabold text-2xl'>
            Offer
        </div>
      <div className="w-full px-10 py-10 flex justify-end">
          <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>setshow(true)}>Add Offer</button>
      </div>
      <div className="container mx-auto px-4 sm:px-8">
  <div className="py-8">
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Image / Offer Name
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Offer Description
              </th>
            </tr>
          </thead>
          <tbody>
          {initState.offer.map((lis)=>{
              return(
                <tr key={lis.offer_id} className="cursor-pointer" onClick={()=>{
                  setoffer_id(lis.offer_id);
                  setoffer_name(lis.offer_name);
                  setoffer_name_ar(lis.offer_name_ar);
                  setoffer_name_ku(lis.offer_name_ku);
                  setoffer_description(lis.offer_description);
                  setoffer_description_ar(lis.offer_description_ar);
                  setoffer_description_ku(lis.offer_description_ku);
                  setoffer_image(lis.offer_image);
                  setshow1(true);
                }}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex">
                  <div className="flex-shrink-0 w-10 h-10">
                    <img
                      className="w-full h-full rounded-full"
                      src={constant.host+'uploads/'+lis.offer_image}
                      alt={lis.offer_name}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {lis.offer_name}
                    </p>
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.offer_description}</p>
              </td>
                </tr>
                )
          }
          )
        }
          </tbody>
          </table>
          </div>
          </div>
          </div>
          </div>
    </div>
  )
}

export default Offer