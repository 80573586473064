import axios from "axios";
import constant from "../../../util/constant";


export default (tokenString) => initDispatch => clear => {
    initDispatch({ type: 'loadingTrue' });
    axios.post(`${constant.host}admin/init.php`, { token: tokenString }).then(({ data }) => {
        if (data.status == 'ok') {
            initDispatch({ type: 'setinit', payload: { brand: data.brand, active_brand: data.active_brand, category: data.category, active_category: data.active_category, item: data.item, user: data.user, order: data.order, cover: data.cover, brand_number: data.brand_number, active_brand_number: data.active_brand_number, deactive_brand_number: data.deactive_brand_number, category_number: data.category_number, active_category_number: data.active_category_number, deactive_category_number: data.deactive_category_number, item_number: data.item_number, active_item_number: data.active_item_number, deactive_item_number: data.deactive_item_number, user_number: data.user_number, active_user_number: data.active_user_number, deactive_user_number: data.deactive_user_number, order_number: data.order_number, accept_order_number: data.accept_order_number, reject_order_number: data.reject_order_number, wait_order_number: data.wait_order_number, offer: data.offer, admin: data.admin } });
        } else {
            clear();
        }
    });
}