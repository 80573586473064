import React from "react";
import numberbeautify from "../util/numberbeautify";
import { BiHomeAlt } from "react-icons/bi";
import { TbBrandAsana } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { SiApostrophe } from "react-icons/si";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FiShoppingCart } from "react-icons/fi";
import { MdOutlineAnalytics } from "react-icons/md";

const Dashboardcard = ({ total, active, deactive, Icon, title }) => {
  return (
    <div className="max-w-sm p-4 my-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 w-2/3 md:w-1/3 mx-2 flex flex-col-reverse lg:flex-row">
      <div className="md:w-full lg:w-1/2">
        <div className="w-full h-10 bg-zinc-500 rounded-lg my-2 flex justify-center items-center font-bold text-zinc-800">
          {numberbeautify(total)}
        </div>
        <div className="w-full h-10 bg-green-400 rounded-lg my-2 flex justify-center items-center font-bold text-green-700">
          {numberbeautify(active)}
        </div>
        <div className="w-full h-10 bg-red-400 rounded-lg my-2 flex justify-center items-center font-bold text-red-700">
          {numberbeautify(deactive)}
        </div>
      </div>
      <div className="md:w-full lg:w-1/2 flex flex-col justify-center items-center">
        <Icon size={38} />
        <div>{title}</div>
      </div>
      <div></div>
    </div>
  );
};

export default Dashboardcard;
