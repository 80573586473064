import { useaxios } from "../../../util/useaxios";
export default (text) => recaller => setrecaller => initDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // setloading(true);
    useaxios('item/search.php', { text }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            initDispatch({ type: 'setsearchitem', payload: res.data });
        } else {
            // clear();
        }
        // setloading(false);
    });
}