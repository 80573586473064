import React,{useContext} from "react";
import Dashboardcard from "../../component/dashboardcard";
import { BiHomeAlt } from "react-icons/bi";
import { TbBrandAsana } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { SiApostrophe } from "react-icons/si";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FiShoppingCart } from "react-icons/fi";
import { MdOutlineAnalytics } from "react-icons/md";
import numberbeautify from "../../util/numberbeautify";
import { GlobalContext } from "../../context/provider";

const Home = () => {
  const {initState}=useContext(GlobalContext);
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="w-full h-40 flex justify-center items-end font-extrabold text-2xl">
        Dashboard
      </div>
      <div class="flex flex-wrap justify-center py-6 lg:justify-around">
        <Dashboardcard
          total={numberbeautify(initState.brand_number)}
          active={numberbeautify(initState.active_brand_number)}
          deactive={numberbeautify(initState.deactive_brand_number)}
          Icon={TbBrandAsana}
          title="Brand"
        />
        <Dashboardcard
          total={numberbeautify(initState.category_number)}
          active={numberbeautify(initState.active_category_number)}
          deactive={numberbeautify(initState.deactive_category_number)}
          Icon={BiCategory}
          title="Category"
        />
        <Dashboardcard
          total={numberbeautify(initState.item_number)}
          active={numberbeautify(initState.active_item_number)}
          deactive={numberbeautify(initState.deactive_item_number)}
          Icon={SiApostrophe}
          title="Item"
        />
        <Dashboardcard
          total={numberbeautify(initState.user_number)}
          active={numberbeautify(initState.active_user_number)}
          deactive={numberbeautify(initState.deactive_user_number)}
          Icon={HiOutlineUserGroup}
          title="User"
        />
        <Dashboardcard
          total={numberbeautify(initState.order_number)}
          active={numberbeautify(initState.accept_order_number)}
          deactive={numberbeautify(initState.reject_order_number+initState.wait_order_number)}
          Icon={FiShoppingCart}
          title="Order"
        />
        <Dashboardcard
          total={numberbeautify(initState.order_number)}
          active={numberbeautify(initState.accept_order_number)}
          deactive={numberbeautify(initState.reject_order_number+initState.wait_order_number)}
          Icon={FiShoppingCart}
          title="Order"
        />
      </div>
    </div>
  );
};

export default Home;
