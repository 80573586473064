import React,{useState,useContext} from 'react';
import { GlobalContext } from '../../context/provider';
import constant from '../../util/constant';
import numberbeautify from '../../util/numberbeautify';
import Addcovermodal from './addcovermodal';
import Editcovermodal from './editcovermodal';

const Cover = () => {
  const {initState}=useContext(GlobalContext);

    const [cover_id,setcover_id]=useState();
    const [txt1,settxt1]=useState();
    const [txt1ar,settxt1ar]=useState();
    const [txt1ku,settxt1ku]=useState();
    const [txt2,settxt2]=useState();
    const [txt2ar,settxt2ar]=useState();
    const [txt2ku,settxt2ku]=useState();
    const [txt3,settxt3]=useState();
    const [txt3ar,settxt3ar]=useState();
    const [txt3ku,settxt3ku]=useState();

  const [show,setshow]=useState(false);
  const [show1,setshow1]=useState(false);
  return (
    <div className='onprint'>
        <Addcovermodal show={show} setshow={setshow} />
        <Editcovermodal show={show1} setshow={setshow1} cover_id={cover_id} txt1={txt1} settxt1={settxt1} txt1ar={txt1ar} settxt1ar={settxt1ar} txt1ku={txt1ku} settxt1ku={settxt1ku} 
        txt2={txt2} settxt2={settxt2} txt2ar={txt2ar} settxt2ar={settxt2ar} txt2ku={txt2ku} settxt2ku={settxt2ku} txt3={txt3} settxt3={settxt3} txt3ar={txt3ar} settxt3ar={settxt3ar} txt3ku={txt3ku} settxt3ku={settxt3ku} />
        <div className='w-full h-40 flex justify-center items-end font-extrabold text-2xl'>
            Cover
      </div>
      <div className="w-full px-10 py-10 flex justify-end">
          <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>setshow(true)}>Add Cover</button>
      </div>
      <div className="container mx-auto px-4 sm:px-8">
  <div className="py-8">
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Image / Txt 1
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Txt 2
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Txt 3
              </th>
            </tr>
          </thead>
          <tbody>
          {initState.cover.map((lis)=>{
              return(
                <tr key={lis.cover_id} className="cursor-pointer" onClick={()=>{
                  setcover_id(lis.cover_id);
                  settxt1(lis.cover_txt1_en);
                  settxt1ar(lis.cover_txt1_ar);
                  settxt1ku(lis.cover_txt1_ku);
                  settxt2(lis.cover_txt2_en);
                  settxt2ar(lis.cover_txt2_ar);
                  settxt2ku(lis.cover_txt2_ku);
                  settxt3(lis.cover_txt3_en);
                  settxt3ar(lis.cover_txt3_ar);
                  settxt3ku(lis.cover_txt3_ku);
                  setshow1(true);
                }}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex">
                  <div className="flex-shrink-0 w-10 h-10">
                    <img
                      className="w-full h-full rounded-full"
                      src={constant.host+'uploads/'+lis.cover_image}
                      alt={lis.cover_txt1_en}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {lis.cover_txt1_en}
                    </p>
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.cover_txt2_en}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.cover_txt3_en}</p>
              </td>
                </tr>
                )
          }
          )
        }
          </tbody>
          </table>
          </div>
          </div>
          </div>
          </div>
    </div>
  )
}

export default Cover