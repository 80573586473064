import { useEffect, useReducer, createContext, useState } from "react";
import initaction from "./action/init/initaction";
import initstate from "./initialstate/initstate";
import Initreducer from "./reducer/initreducer";

export const GlobalContext = createContext({});
const GlobalProvider = ({ children }) => {
    const [recaller, setrecaller] = useState(0);

    const [initState, initDispatch] = useReducer(Initreducer, initstate);

    const clear = () => {
        initDispatch({ type: 'loadingTrue' });
        localStorage.setItem('naturalselectiontoken', null);
        initDispatch({ type: 'settoken', payload: null });
        initDispatch({ type: 'loadingFalse' });
    }

    useEffect(() => {
        const tokenString = localStorage.getItem('naturalselectiontoken');
        if (tokenString != null && tokenString != 'null') {
            initDispatch({ type: 'settoken', payload: tokenString });
        }
        initaction(tokenString)(initDispatch)(clear);
    }, [recaller]);

    return <GlobalContext.Provider value={{ initState, initDispatch, recaller, setrecaller, clear }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider