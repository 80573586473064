import axios from "axios";
import constant from '../../../util/constant';

export default (name, category_name_ar, category_name_ku, brand_id, images) => recaller => setrecaller => initDispatch => {
    initDispatch({ type: 'LoadingTrue' });
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let file = [];
    if (images.target) {
        file = images.target.files;
    }
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('file[]', file[i]);
    }
    formData.append('category_name', name);
    formData.append('category_name_ar', category_name_ar);
    formData.append('category_name_ku', category_name_ku);
    formData.append('brand_id', brand_id);
    formData.append('category_date', today);
    axios.post(`${constant.host}category/create.php`,
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }
    ).then((res) => {
        // initDispatch({ type: 'LoadingFalse' });
        if (res.data.status == 'ok') {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    })
}