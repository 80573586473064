import axios from "axios";
import constant from '../../../util/constant';

export default (cover_txt1_en, cover_txt1_ar, cover_txt1_ku, cover_txt2_en, cover_txt2_ar, cover_txt2_ku, cover_txt3_en, cover_txt3_ar, cover_txt3_ku, images) => recaller => setrecaller => initDispatch => {
    initDispatch({ type: 'LoadingTrue' });
    let file = [];
    if (images.target) {
        file = images.target.files;
    }
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('file[]', file[i]);
    }
    formData.append('cover_txt1_en', cover_txt1_en);
    formData.append('cover_txt1_ar', cover_txt1_ar);
    formData.append('cover_txt1_ku', cover_txt1_ku);

    formData.append('cover_txt2_en', cover_txt2_en);
    formData.append('cover_txt2_ar', cover_txt2_ar);
    formData.append('cover_txt2_ku', cover_txt2_ku);

    formData.append('cover_txt3_en', cover_txt3_en);
    formData.append('cover_txt3_ar', cover_txt3_ar);
    formData.append('cover_txt3_ku', cover_txt3_ku);
    axios.post(`${constant.host}cover/create.php`,
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }
    ).then((res) => {
        // initDispatch({ type: 'LoadingFalse' });
        if (res.data.status == 'ok') {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    })
}