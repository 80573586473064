import React,{useContext, useState} from 'react'
import { GlobalContext } from '../../context/provider';
import constant from '../../util/constant';
import Addcategorymodal from './addcategorymodal';
import Editcategorymodal from './editcategorymodal';

const Category = () => {
  const {initState}=useContext(GlobalContext);

  const [category_id,setcategory_id]=useState();
  const [brand_id,setbrand_id]=useState();
  const [category_name,setcategory_name]=useState();
  const [category_namear,setcategory_namear]=useState();
  const [category_nameku,setcategory_nameku]=useState();
  const [category_image,setcategory_image]=useState();

  const [show,setshow]=useState(false);
  const [show1,setshow1]=useState(false);
  return (
    <div className='onprint'>
      <Addcategorymodal show={show} setshow={setshow} />
      <Editcategorymodal show={show1} setshow={setshow1} category_id={category_id} category_name={category_name} category_namear={category_namear} setcategory_namear={setcategory_namear} category_nameku={category_nameku} setcategory_nameku={setcategory_nameku} setcategory_name={setcategory_name} brand_id={brand_id} setbrand_id={setbrand_id} />
      <div className='w-full h-40 flex justify-center items-end font-extrabold text-2xl'>
            Categorys
      </div>
      <div className="w-full px-10 py-10 flex justify-end">
          <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>setshow(true)}>Add Category</button>
      </div>
      <div className='px-10 flex flex-wrap'>
        {initState.category.map((lis)=>(
          <div className="max-w-xs rounded overflow-hidden shadow-lg mb-5 mx-2 cursor-pointer" onClick={()=>{
              setcategory_id(lis.category_id);
              setbrand_id(lis.brand_id);
              setcategory_name(lis.category_name);
              setcategory_namear(lis.category_name_ar);
              setcategory_nameku(lis.category_name_ku);
              setcategory_image(lis.category_name);
              setshow1(true);
          }} key={lis.category_id}>
            <img className="w-96 h-60" src={constant.host+'uploads/'+lis.category_image} alt={lis.category_name} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{lis.category_name}</div>
              <p className="text-gray-700 text-base">
                {lis.brand_name}
              </p>
            </div>
            <div className="px-6 pt-4 pb-2">
              <span className={lis.category_state=='active'?"inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-900 mr-2 mb-2":"inline-block bg-red-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-900 mr-2 mb-2"}>{lis.category_state}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Category