import { useaxios } from "../../../util/useaxios";
export default (username, password) => initDispatch => {
    initDispatch({ type: 'loadingTrue' });
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // setloading(true);
    useaxios('admin/login.php', { username, password }).then((res) => {
        if (res.status == "ok") {
            localStorage.setItem('naturalselectiontoken', res.detail.admin_id);
            initDispatch({ type: 'settoken', payload: res.detail.admin_id });
            window.open('/', "_self");
        } else {
            initDispatch({ type: 'authcheckTrue' });
            // clear();
        }
        initDispatch({ type: 'loadingFalse' });
        // setloading(false);
    });
}