import { useaxios } from "../../../util/useaxios";
export default (item_image_id) => recaller => setrecaller => item_image => setitem_image => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // setloading(true);
    useaxios('item_image/delete.php', { item_image_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
            setitem_image(item_image.filter((lis) => lis.item_image_id != item_image_id));
        } else {
            // clear();
        }
        // setloading(false);
    });
}