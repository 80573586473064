import axios from "axios";
import constant from '../../../util/constant';

export default (offer_name, offer_name_ar, offer_name_ku, offer_description, offer_description_ar, offer_description_ku, images) => recaller => setrecaller => initDispatch => {
    initDispatch({ type: 'LoadingTrue' });
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let file = [];
    if (images.target) {
        file = images.target.files;
    }
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('file[]', file[i]);
    }
    formData.append('offer_name', offer_name);
    formData.append('offer_name_ar', offer_name_ar);
    formData.append('offer_name_ku', offer_name_ku);

    formData.append('offer_description', offer_description);
    formData.append('offer_description_ar', offer_description_ar);
    formData.append('offer_description_ku', offer_description_ku);
    formData.append('offer_start', today);
    axios.post(`${constant.host}offer/create.php`,
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }
    ).then((res) => {
        // initDispatch({ type: 'LoadingFalse' });
        if (res.data.status == 'ok') {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    })
}