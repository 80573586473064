export default {
    isLogin: false,
    loading: true,
    token: null,
    authcheck: false,
    brand: [],
    active_brand: [],
    category: [],
    active_category: [],
    item: [],
    user: [],
    order: [],
    cover: [],
    offer: [],
    admin: [],
    brand_number: 0,
    active_brand_number: 0,
    deactive_brand_number: 0,
    category_number: 0,
    active_category_number: 0,
    deactive_category_number: 0,
    item_number: 0,
    active_item_number: 0,
    deactive_item_number: 0,
    user_number: 0,
    active_user_number: 0,
    deactive_user_number: 0,
    order_number: 0,
    accept_order_number: 0,
    reject_order_number: 0,
    wait_order_number: 0,
    user_profile: null
}