
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";
import React, { useContext, useState } from 'react';
import Menu from "./component/menu";
import Brand from "./page/brand/brand";
import Category from "./page/category/category";
import Home from "./page/home/home";
import Item from "./page/item/item";
import Order from "./page/order/order";
import User from "./page/user/user";
import { GlobalContext } from './context/provider';
import Loading from "./component/loading";
import Login from "./component/login";
import { TbLogout } from "react-icons/tb";
import { AiOutlineMenu } from "react-icons/ai";
import Cover from "./page/cover/cover";
import Userprofile from "./page/user/userprofile";
import './component/css/printstyle.css';
import Offer from "./page/offer/offer";
import Admin from "./page/admin/admin";
function App() {
  const { initState, clear } = useContext(GlobalContext);

  const [menutoggle, setmenutoggle] = useState(false);
  return (
    <Router>
      {initState.loading == true ? <Loading /> :
        initState.token == null ?
          <Login />
          :
          <div className="w-full h-screen flex">
            <div className="md:w-96 md:h-full border-r-[1px] border-gray-400 onprint">
              <Menu menutoggle={menutoggle} setmenutoggle={setmenutoggle} />
            </div>
            <div className="w-full h-full">
              <div className="w-full h-16 border-b-2 flex md:justify-end justify-between items-center px-10 onprint">
                <AiOutlineMenu size={30} className="md:hidden cursor-pointer" onClick={() => { setmenutoggle(true) }} />
                <TbLogout size={30} className="cursor-pointer" onClick={() => { clear() }} />
              </div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/brand" element={<Brand />} />
                <Route path="/category" element={<Category />} />
                <Route path="/item" element={<Item />} />
                <Route path="/user" element={<User />} />
                <Route path="/order" element={<Order />} />
                <Route path="/user/profile/:id" element={<Userprofile />} />
                <Route path="/cover" element={<Cover />} />
                <Route path="/offer" element={<Offer />} />
                <Route path="/admin" element={<Admin />} />
              </Routes>
            </div>
          </div>
      }
    </Router>
  );
}

export default App;
