import React,{useState,useContext} from 'react';
import { GlobalContext } from '../../context/provider';
import constant from '../../util/constant';
import numberbeautify from '../../util/numberbeautify';
import Additemmodal from './additemmodal';
import Edititemmodal from './edititemmodal';
import searchaction from '../../context/action/item/searchaction';

const Item = () => {
  const {initState, recaller, setrecaller, initDispatch}=useContext(GlobalContext);

  const [item_id,setitem_id]=useState();
  const [item_name,setitem_name]=useState();
  const [item_namear,setitem_namear]=useState();
  const [item_nameku,setitem_nameku]=useState();
  const [item_price,setitem_price]=useState();
  const [brand_id,setbrand_id]=useState();
  const [category_id,setcategory_id]=useState();
  const [item_description,setitem_description]=useState();
  const [item_descriptionar,setitem_descriptionar]=useState();
  const [item_descriptionku,setitem_descriptionku]=useState();
  const [item_offer,setitem_offer]=useState();
  const [item_offer_ar,setitem_offer_ar]=useState();
  const [item_offer_ku,setitem_offer_ku]=useState();
  const [item_image,setitem_image]=useState([]);
  const [categorys,setcategorys]=useState([]);

  const [show,setshow]=useState(false);
  const [show1,setshow1]=useState(false);
  return (
    <div className='onprint'>
      <Additemmodal show={show} setshow={setshow} />
      <Edititemmodal show={show1} setshow={setshow1} item_id={item_id} item_name={item_name} setitem_name={setitem_name} item_name_ar={item_namear} setitem_namear={setitem_namear} item_name_ku={item_nameku} setitem_nameku={setitem_nameku} item_price={item_price} setitem_price={setitem_price} brand_id={brand_id} setbrand_id={setbrand_id} category_id={category_id} setcategory_id={setcategory_id} item_description={item_description} setitem_description={setitem_description} item_description_ar={item_descriptionar} setitem_descriptionar={setitem_descriptionar} item_description_ku={item_descriptionku} setitem_descriptionku={setitem_descriptionku} item_image={item_image} setitem_image={setitem_image} categorys={categorys} setcategorys={setcategorys} item_offer={item_offer} setitem_offer={setitem_offer} item_offer_ar={item_offer_ar} setitem_offer_ar={setitem_offer_ar} item_offer_ku={item_offer_ku} setitem_offer_ku={setitem_offer_ku} /> 
      <div className='w-full h-40 flex justify-center items-end font-extrabold text-2xl'>
            Items
      </div>
      <div className="w-full px-10 py-10 flex justify-between">
      <div className="w-full md:w-2/3 px-3">
                      {/* <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Item Search
                      </label> */}
                      <input
                        className={
                          "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="Search ..."
                        onChange={(val) => {
                          searchaction(val.target.value)(recaller)(setrecaller)(initDispatch)
                        }}
                      />
                    </div>
          <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>setshow(true)}>Add Items</button>
      </div>
      
      <div className="container mx-auto px-4 sm:px-8">
  <div className="py-3">
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Name / Brand
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Price
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Category
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Status
              </th>
           
            </tr>
          </thead>
          <tbody>
            {initState.item.map((lis)=>{
              var img=lis.image.length>0?lis.image[0].item_image_name:'undifined';
              return(
              <tr key={lis.item_id} className="cursor-pointer" onClick={()=>{
                setitem_id(lis.item_id);
                setitem_name(lis.item_name);
                setitem_namear(lis.item_name_ar);
                setitem_nameku(lis.item_name_ku);
                setitem_price(lis.item_price);
                setbrand_id(lis.brand_id);
                setcategory_id(lis.category_id);
                setitem_description(lis.item_description);
                setitem_descriptionar(lis.item_description_ar);
                setitem_descriptionku(lis.item_description_ku);
                setitem_image(lis.image);
                setcategorys(initState.active_category.filter((res)=>res.brand_id==lis.brand_id));
                setitem_offer(lis.item_offer);
                setitem_offer_ar(lis.item_offer_ar);
                setitem_offer_ku(lis.item_offer_ku);
                setshow1(true);
              }}>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex">
                  <div className="flex-shrink-0 w-10 h-10">
                    <img
                      className="w-full h-full rounded-full"
                      src={constant.host+'uploads/'+img}
                      alt={lis.item_name}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {lis.item_name}
                    </p>
                    <p className="text-gray-600 whitespace-no-wrap">{lis.brand_name}</p>
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{numberbeautify(lis.item_price)}</p>
                <p className="text-gray-600 whitespace-no-wrap">USD</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.category_name}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <span
                  className={lis.item_state=='active'?"relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight":"relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"}
                >
                  <span
                    aria-hidden
                    className={lis.item_state=='active'?"absolute inset-0 bg-green-200 opacity-50 rounded-full":"absolute inset-0 bg-red-200 opacity-50 rounded-full"}
                  ></span>
                  <span className="relative">{lis.item_state}</span>
                </span>
              </td>
            </tr>
              )
            }
              )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
  )
}

export default Item