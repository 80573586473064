import React from 'react';
import logo from '../../assets/image/logo.png';
import numberbeautify from '../../util/numberbeautify';
import { TbWorldWww } from "react-icons/tb";
import { HiOutlinePhone } from "react-icons/hi";
import { FiInstagram } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";

const Orderprint = ({order_detail}) => {
  return (
    <div className='printpage'>
        <div className='w-full m-4'>
          <div className='flex justify-center'>
            <img src={logo} className='w-24 h-24' />
          </div>
          <div className='flex justify-center text-2xl text-lime-700'>
            Natural Selection
          </div>
        </div>
        <div className='mt-6 px-8'>
          <div className='flex justify-between'>
            <div>Order number : {order_detail?.invoice_id}</div>
            <div>Send to : {order_detail?.username}</div>
            <div>Phone : {order_detail?.user_phone}</div>
          </div>
          <div className='flex my-5'>
            <div>Address : {order_detail?.user_address}</div>
          </div>
        </div>
        <div className='w-full flex justify-around text-sm my-5 py-2 border-t-2 border-b-2 text-lime-800 border-lime-600'>
            <div className='flex'><TbWorldWww size={20} className='mx-2' /> https://naturalselection.shop/</div>
            <div className='flex'><HiOutlinePhone size={20} className='mx-2' /> +964 750 491 6034</div>
            <div className='flex'><FiInstagram size={20} className='mx-2' /> thaliabeauty.iq</div>
            <div className='flex'><AiOutlineMail size={20} className='mx-2' /> thaliabeauty0@gmail.com</div>
        </div>
        <div className='px-4'>
          <table className='w-full mt-5 border-2'>
            <thead className='border-2'>
                <th className='border-2'>Item Name</th>
                <th className='border-2'>Price</th>
                <th className='border-2'>Qty</th>
                <th className='border-2'>Sub Price</th>
            </thead>
            <tbody>
              {order_detail.item?.map((lis)=>(
                <tr className='border-2'>
                  <td className='border-2 text-center'>{lis.item_name}</td>
                  <td className='border-2 text-center'>{numberbeautify(lis.item_price)}</td>
                  <td className='border-2 text-center'>{lis.item_qty}</td>
                  <td className='border-2 text-center'>{numberbeautify((Number(lis.item_price*lis.item_qty)))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default Orderprint