import React,{useState,useContext,useEffect} from 'react';
import { GlobalContext } from '../../context/provider';
import { Link, useLocation } from "react-router-dom";
import profileaction from '../../context/action/user/profileaction';
import numberbeautify from '../../util/numberbeautify';
import Userordermodal from './userordermodal';


const Userprofile = () => {
  const {initState, recaller, setrecaller, initDispatch}=useContext(GlobalContext);
  const {state} = useLocation();
  const [show,setshow]=useState(false);
  const [item,setitem]=useState([]);
  useEffect(()=>{
    if(!state||state==""){
        window.open('http://localhost:3000', "_self");
    }else{
        profileaction(state)(recaller)(setrecaller)(initDispatch);
    }
  },[])

  return (
    <div>
        <Userordermodal show={show} setshow={setshow} item={item} />
        <div className='py-5 flex justify-around'>
            <label className={"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"}>
            Name: {initState.user_profile?initState.user_profile.username:""}
            </label>
            <label className={"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"}>
            Phone: {initState.user_profile?initState.user_profile.user_phone:""}
            </label>
            <label className={"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"}>
            Address: {initState.user_profile?initState.user_profile.user_address:""}
            </label>
            <label className={"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"}>
            Age: {initState.user_profile?initState.user_profile.user_age:""}
            </label>
            <label className={"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"}>
            Gender: {initState.user_profile?initState.user_profile.user_gender:""}
            </label>
        </div>
        <div className='border-b-2 px-5'>
        <label className={"block uppercase tracking-wide text-gray-700 text-s font-bold mb-2"}> Order History</label>
        </div>
        <div className="container mx-auto px-4 sm:px-8">
  <div className="py-3">
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
            <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Order Id
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Name / Phone
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Total Qty
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Total Price
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {initState.user_profile?initState.user_profile.invoice.map((lis)=>(
              <tr key={lis.invoice_id} className="cursor-pointer" onClick={()=>{
                setitem(lis.item);
                setshow(true);
              }}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.invoice_id}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex">
                  <Link to={`/user/profile/${lis.user_id}`} state={lis.user_id} className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">
                       {lis.username}
                    </p>
                    <p className="text-gray-600 whitespace-no-wrap">{lis.user_phone}</p>
                  </Link>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.total_qty}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{numberbeautify(lis.total_price)}</p>
                <p className="text-gray-600 whitespace-no-wrap">USD</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <span
                  className={lis.invoice_state=='approve'?"relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight":lis.invoice_state=='wait'?"relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight":"relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"}
                >
                  <span
                    aria-hidden
                    className={lis.invoice_state=='approve'?"absolute inset-0 bg-green-200 opacity-50 rounded-full":lis.invoice_state=='wait'?"absolute inset-0 bg-orange-200 opacity-50 rounded-full":"absolute inset-0 bg-red-200 opacity-50 rounded-full"}
                  ></span>
                  <span className="relative">{lis.invoice_state}</span>
                </span>
              </td>
            </tr>
              )):null}
          </tbody>
          </table>
          </div>
          </div>
          </div>
          </div>
    </div>
  )
}

export default Userprofile