import { useaxios } from "../../../util/useaxios";
export default (admin_name, admin_password, admin_phone, admin_role, admin_id) => recaller => setrecaller => initDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // setloading(true);
    useaxios('admin/update.php', { admin_name, admin_password, admin_phone, admin_role, admin_id }).then((res) => {
        initDispatch({ type: 'LoadingTrue' });
        if (res.status == "ok") {
            setrecaller(recaller + 1);
            // initDispatch({ type: 'setsearchorder', payload: res.data });
        } else {
            // clear();
        }
        // setloading(false);
    });
}