import axios from "axios";
import constant from '../../../util/constant';

export default (item_name, item_name_ar, item_name_ku, item_price, brand_id, category_id, description, item_description_ar, item_description_ku, item_offer, item_offer_ar, item_offer_ku, item_id, images) => recaller => setrecaller => initDispatch => {
    initDispatch({ type: 'LoadingTrue' });
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let file = [];
    if (images) {
        file = images.target.files;
    }
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('file[]', file[i]);
    }
    formData.append('item_name', item_name);
    formData.append('item_name_ar', item_name_ar);
    formData.append('item_name_ku', item_name_ku);
    formData.append('category_id', category_id);
    formData.append('brand_id', brand_id);
    formData.append('item_description', description);
    formData.append('item_description_ar', item_description_ar);
    formData.append('item_description_ku', item_description_ku);
    formData.append('item_offer', item_offer);
    formData.append('item_offer_ar', item_offer_ar);
    formData.append('item_offer_ku', item_offer_ku);
    formData.append('item_price', item_price);
    formData.append('item_id', item_id);
    axios.post(`${constant.host}item/update.php`,
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }
    ).then((res) => {
        console.log(res);
        // initDispatch({ type: 'LoadingFalse' });
        if (res.data.status == 'ok') {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    })
}