import { useaxios } from "../../../util/useaxios";
export default (invoice_id, invoice_state) => recaller => setrecaller => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // setloading(true);
    useaxios('invoice/invoicechangestate.php', { invoice_id, invoice_state }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
        // setloading(false);
    });
}