import axios from "axios";
import constant from "../../../util/constant";


export default (brand_id) => recaller => setrecaller => initDispatch => {
    initDispatch({ type: 'LoadingTrue' });
    axios.post(`${constant.host}brand/delete.php`, { brand_id }).then(({ data }) => {
        if (data.status == 'ok') {
            setrecaller(recaller + 1);
        }
    });
}