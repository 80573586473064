import React, { useContext, useState } from "react";
import createaction from "../../context/action/item/createaction";
import { GlobalContext } from "../../context/provider";

const Additemmodal = (props) => {
  const { initState, recaller, setrecaller, initDispatch } =
    useContext(GlobalContext);

  const [item_name, setitem_name] = useState('');
  const [item_namear, setitem_namear] = useState('');
  const [item_nameku, setitem_nameku] = useState('');
  const [item_price, setitem_price] = useState('');
  const [category_id, setcategory_id] = useState(0);
  const [brand_id, setbrand_id] = useState('');
  const [itemimage, setitemimage] = useState('');
  const [description, setdescription] = useState('');
  const [descriptionar, setdescriptionar] = useState('');
  const [descriptionku, setdescriptionku] = useState('');
  const [categorys, setcategorys] = useState([]);
  const [offer, setoffer] = useState('');
  const [offerar, setofferar] = useState('');
  const [offerku, setofferku] = useState('');


  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);
  const [error3, seterror3] = useState(false);
  const [error4, seterror4] = useState(false);
  const [error5, seterror5] = useState(false);
  const [error6, seterror6] = useState(false);

  const changeimag = (e) => {
    seterror1(false);
    setitemimage(e);
  };
  const selectbrand = (brand_id) => {
    setcategory_id(0);
    if (brand_id == 0 || !brand_id) {
      setcategorys([]);
    } else {
      setcategorys(
        initState.active_category.filter((lis) => lis.brand_id == brand_id)
      );
    }
  };
  return (
    <>
      {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative h-full overflow-y-auto my-6 mx-auto md:w-2/3 lg:w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl uppercase font-semibold">Add Item</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setshow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className={
                          error
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Item Name
                      </label>
                      <input
                        className={
                          error
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={item_name}
                        onChange={(val) => setitem_name(val.target.value)}
                        onFocus={() => seterror(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className={
                          error5
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Item Name Ar
                      </label>
                      <input
                        className={
                          error5
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={item_namear}
                        onChange={(val) => setitem_namear(val.target.value)}
                        onFocus={() => seterror5(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          error6
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Item Name Ku
                      </label>
                      <input
                        className={
                          error6
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={item_nameku}
                        onChange={(val) => setitem_nameku(val.target.value)}
                        onFocus={() => seterror6(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          error1
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-price"
                      >
                        Item Price
                      </label>
                      <input
                        className={
                          error1
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-price"
                        type="number"
                        placeholder="200"
                        value={item_price}
                        onChange={(val) => setitem_price(val.target.value)}
                        onFocus={() => seterror1(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mt-1">
                      <label
                        for="countries"
                        className={
                          error2
                            ? "block mb-2 text-sm font-medium text-red-500"
                            : "block mb-2 text-sm font-medium text-gray-900"
                        }
                      >
                        Select a Brand
                      </label>
                      <select
                        id="countries"
                        className={
                          error2
                            ? "bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        }
                        value={brand_id}
                        onChange={(val) => {
                          selectbrand(val.target.value);
                          setbrand_id(val.target.value);
                        }}
                        onFocus={() => seterror2(false)}
                      >
                        <option value={0}>Choose a Brand</option>
                        {initState.active_brand.map((lis) => (
                          <option value={lis.brand_id} key={lis.brand_id}>
                            {lis.brand_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mt-1">
                      <label
                        for="cate"
                        className={
                          error3
                            ? "block mb-2 text-sm font-medium text-red-500"
                            : categorys.length > 0
                            ? "block mb-2 text-sm font-medium text-gray-900"
                            : "block mb-2 text-sm font-medium text-gray-500"
                        }
                      >
                        Select a Category
                      </label>
                      <select
                        id="cate"
                        className={
                          error3 && categorys.length > 0
                            ? "bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            : error3 && categorys.length < 0
                            ? "bg-gray-200 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            : categorys.length > 0
                            ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            : "bg-gray-200 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        }
                        disabled={categorys.length > 0 ? false : true}
                        value={category_id}
                        onChange={(val) => setcategory_id(val.target.value)}
                        onFocus={() => seterror3(false)}
                      >
                        <option value={0}>Choose a Category</option>
                        {categorys.map((lis) => (
                          <option value={lis.category_id} key={lis.category_id}>
                            {lis.category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-decription"
                      >
                        Item Description
                      </label>
                      <input
                        className={
                          "block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        }
                        id="grid-last-decription"
                        type="text"
                        placeholder=""
                        value={description}
                        onChange={(val) => setdescription(val.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-decription"
                      >
                        Item Description Ar
                      </label>
                      <input
                        className={
                          "block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        }
                        id="grid-last-decription"
                        type="text"
                        placeholder=""
                        value={descriptionar}
                        onChange={(val) => setdescriptionar(val.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-decription"
                      >
                        Item Description Ku
                      </label>
                      <input
                        className={
                          "block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        }
                        id="grid-last-decription"
                        type="text"
                        placeholder=""
                        value={descriptionku}
                        onChange={(val) => setdescriptionku(val.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-decription"
                      >
                        Item Offer
                      </label>
                      <input
                        className={
                          "block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        }
                        id="grid-last-decription"
                        type="text"
                        placeholder=""
                        value={offer}
                        onChange={(val) => setoffer(val.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-decription"
                      >
                        Item Offer Ar
                      </label>
                      <input
                        className={
                          "block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        }
                        id="grid-last-decription"
                        type="text"
                        placeholder=""
                        value={offerar}
                        onChange={(val) => setofferar(val.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 pt-3 px-3">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-decription"
                      >
                        Item Offer Ku
                      </label>
                      <input
                        className={
                          "block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        }
                        id="grid-last-decription"
                        type="text"
                        placeholder=""
                        value={offerku}
                        onChange={(val) => setofferku(val.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-full pt-3 px-3">
                      <label
                        className={
                          error4
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="file_input"
                      >
                        Item Images
                      </label>
                      <input
                        className={
                          error4
                            ? "block w-full mb-1 text-xs text-gray-900 border border-red-500 rounded-lg cursor-pointer py-3 px-4 bg-gray-50"
                            : "block w-full mb-1 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer py-3 px-4 bg-gray-50"
                        }
                        id="file_input"
                        type="file"
                        multiple
                        onChange={changeimag}
                        onFocus={() => seterror4(false)}
                      />
                      <p className="text-sm mb-4">
                        recommended image size is 750px * 750px
                      </p>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setshow(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (item_name == "" || !item_name) {
                        seterror(true);
                        return false;
                      }
                      if (item_price == "" || !item_price || item_price == 0) {
                        seterror1(true);
                        return false;
                      }
                      if (brand_id == null || !brand_id || brand_id == 0) {
                        seterror2(true);
                        return false;
                      }
                      if (
                        category_id == null ||
                        !category_id ||
                        category_id == 0
                      ) {
                        seterror3(true);
                        return false;
                      }
                      if (itemimage == "" || !itemimage) {
                        seterror4(true);
                        return false;
                      }
                      createaction(
                        item_name,
                        item_namear,
                        item_nameku,
                        item_price,
                        brand_id,
                        category_id,
                        description,
                        descriptionar,
                        descriptionku,
                        offer,
                        offerar,
                        offerku,
                        itemimage
                      )(recaller)(setrecaller)(initDispatch);
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Additemmodal;
