import React, { useContext, useState } from "react";
import deleteaction from "../../context/action/brand/deleteaction";
import updateaction from "../../context/action/brand/updateaction";
import { GlobalContext } from "../../context/provider";

const Editbrandmodal = (props) => {
  const { initState, recaller, setrecaller, initDispatch } =
    useContext(GlobalContext);

  const [brand_img, setbrand_img] = useState();

  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);

  const changeimag = (e) => {
    setbrand_img(e);
  };
  return (
    <>
      {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl uppercase font-semibold">
                    Update Brand
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setshow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-full px-3">
                      <label
                        className={
                          error
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Brand Name
                      </label>
                      <input
                        className={
                          error
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.brand_name}
                        onChange={(val) =>
                          props.setbrand_name(val.target.value)
                        }
                        onFocus={() => seterror(false)}
                      />
                    </div>
                    <div className="w-full md:w-full px-3">
                      <label
                        className={
                          error1
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Brand Name Ar
                      </label>
                      <input
                        className={
                          error1
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.brand_namear}
                        onChange={(val) =>
                          props.setbrand_namear(val.target.value)
                        }
                        onFocus={() => seterror1(false)}
                      />
                    </div>
                    <div className="w-full md:w-full px-3">
                      <label
                        className={
                          error2
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Brand Name Ku
                      </label>
                      <input
                        className={
                          error2
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.brand_name_ku}
                        onChange={(val) =>
                          props.setbrand_nameku(val.target.value)
                        }
                        onFocus={() => seterror2(false)}
                      />
                    </div>
                    <div className="w-full md:w-full px-3">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="file_input"
                      >
                        Brand Image
                      </label>
                      <input
                        className={
                          "block w-full mb-1 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer py-3 px-4 bg-gray-50"
                        }
                        id="file_input"
                        type="file"
                        onChange={changeimag}
                      />
                      <p className="text-sm">
                        recommended image size is 750px * 750px
                      </p>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (
                        window.confirm(
                          "ئاگاداری ئەنجامدانی ڕەشکردنەوە . دڵنیای ؟"
                        ) == true
                      ) {
                        deleteaction(props.brand_id)(recaller)(setrecaller)(
                          initDispatch
                        );
                      }
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (props.brand_name == "" || !props.brand_name) {
                        seterror(true);
                        return false;
                      }
                      if (props.brand_namear == "" || !props.brand_namear) {
                        seterror1(true);
                        return false;
                      }
                      if (props.brand_name_ku == "" || !props.brand_name_ku) {
                        seterror2(true);
                        return false;
                      }
                      if (
                        window.confirm(
                          "ئاگاداری ئەنجامدانی گۆڕانکاری . دڵنیای ؟"
                        ) == true
                      ) {
                        updateaction(
                          props.brand_id,
                          props.brand_name,
                          props.brand_namear,
                          props.brand_name_ku,
                          brand_img
                        )(recaller)(setrecaller)(initDispatch);
                      }
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Editbrandmodal;
