import React,{useState,useContext} from 'react';
import { GlobalContext } from '../../context/provider';
import constant from '../../util/constant';
import { Link, useLocation } from "react-router-dom";
import numberbeautify from '../../util/numberbeautify';
import Orderdetailmodal from './orderdetailmodal';
import searchaction from '../../context/action/order/searchaction';
import Orderprint from './orderprint';

const Order = () => {
  const {initState, recaller, setrecaller, initDispatch}=useContext(GlobalContext);

  const [order_detail,setorder_detail]=useState({});
  const [invoice_id,setinvoice_id]=useState(0);
  const [show,setshow]=useState(false);
  const [show1,setshow1]=useState(false);

  const printit=()=>{
    window.print();
  }
  return (
    <div>
      <Orderprint order_detail={order_detail} />
      <div className='onprint'>
      <Orderdetailmodal show={show} setshow={setshow} order_detail={order_detail} invoice_id={invoice_id} printit={printit} />
      <div className='w-full h-40 flex justify-center items-end font-extrabold text-2xl '>
            Orders
      </div>
      <div className="w-full px-10 py-10 flex justify-between">
        <div className="w-full md:w-full px-1">
          <input
            className={
              "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Search ..."
            onChange={(val) => {
              searchaction(val.target.value)(recaller)(setrecaller)(initDispatch)
            }}
          />
        </div>
      </div>
      <div className="container mx-auto px-4 sm:px-8">
  <div className="py-3">
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
            <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Order Id
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Name / Phone
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Total Qty
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Total Price
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {initState.order.map((lis)=>(
              <tr key={lis.invoice_id} className="cursor-pointer" onDoubleClick={()=>{
                setorder_detail(lis);
                setinvoice_id(lis.invoice_id);
                setshow(true);
              }}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.invoice_id}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex">
                  <Link to={`/user/profile/${lis.user_id}`} state={lis.user_id} className="ml-3">
                    <p className="text-blue-800 whitespace-no-wrap">
                       {lis.username}
                    </p>
                    <p className="text-blue-700 whitespace-no-wrap">{lis.user_phone}</p>
                  </Link>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.total_qty}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{numberbeautify(lis.total_price)}</p>
                <p className="text-gray-600 whitespace-no-wrap">USD</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <span
                  className={lis.invoice_state=='approve'?"relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight":lis.invoice_state=='wait'?"relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight":"relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"}
                >
                  <span
                    aria-hidden
                    className={lis.invoice_state=='approve'?"absolute inset-0 bg-green-200 opacity-50 rounded-full":lis.invoice_state=='wait'?"absolute inset-0 bg-orange-200 opacity-50 rounded-full":"absolute inset-0 bg-red-200 opacity-50 rounded-full"}
                  ></span>
                  <span className="relative">{lis.invoice_state}</span>
                </span>
              </td>
            </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
    </div>
  )
}

export default Order