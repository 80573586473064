import React,{useContext, useState} from 'react'
import { GlobalContext } from '../../context/provider';
import constant from '../../util/constant';
import Addadminmodal from './addadminmodal';
import Editadminmodal from './editadminmodal';
import changestateaction from '../../context/action/admin/changestateaction';

const Admin = () => {
  const {initState,recaller, setrecaller, initDispatch}=useContext(GlobalContext);

  const [admin_id,setadmin_id]=useState(0);
  const [admin_name,setadmin_name]=useState('');
  const [admin_password,setadmin_password]=useState('');
  const [admin_phone,setadmin_phone]=useState('');
  const [admin_role,setadmin_role]=useState('');

  const [show,setshow]=useState(false);
  const [show1,setshow1]=useState(false);
  return (
    <div className='onprint'>
      <Addadminmodal show={show} setshow={setshow} />
      <Editadminmodal show={show1} setshow={setshow1} admin_id={admin_id} admin_name={admin_name} setadmin_name={setadmin_name} admin_password={admin_password} setadmin_password={setadmin_password} admin_phone={admin_phone} setadmin_phone={setadmin_phone} admin_role={admin_role} setadmin_role={setadmin_role} />
        <div className='w-full h-40 flex justify-center items-end font-extrabold text-2xl'>
            Admins
        </div>
        <div className="w-full px-10 py-10 flex justify-end">
          <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>setshow(true)}>Add Admin</button>
        </div>
        
        <div className="container mx-auto px-4 sm:px-8">
  <div className="py-3">
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div
        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
      >
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Admin Name
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Admin Phone
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Status
              </th>
           
            </tr>
          </thead>
          <tbody>
            {initState.admin.map((lis)=>{
              return(
              <tr key={lis.item_id}>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer" onClick={()=>{
                setadmin_id(lis.admin_id);
                setadmin_name(lis.admin_name);
                setadmin_password(lis.admin_password);
                setadmin_phone(lis.admin_phone);
                setadmin_role(lis.admin_role);
                setshow1(true);
              }}>
                <p className="text-gray-900 whitespace-no-wrap">{lis.admin_name}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lis.admin_phone}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer" onClick={()=>{
                if (
                  window.confirm(
                    "ئاگاداری ئەنجامدانی گۆڕانکاری دۆخی ئەدمین. دڵنیای ؟"
                  ) == true
                ) {
                  changestateaction(lis.admin_state,lis.admin_id)(recaller)(setrecaller)(initDispatch);
                }
              }}>
                <span
                  className={lis.admin_state=='active'?"relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight":"relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"}
                >
                  <span
                    aria-hidden
                    className={lis.admin_state=='active'?"absolute inset-0 bg-green-200 opacity-50 rounded-full":"absolute inset-0 bg-red-200 opacity-50 rounded-full"}
                  ></span>
                  <span className="relative">{lis.admin_state}</span>
                </span>
              </td>
            </tr>
              )
            }
              )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Admin