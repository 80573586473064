import axios from "axios";
import constant from '../../../util/constant';

export default (category_id, brand_id, name, category_name_ar, category_name_ku, images) => recaller => setrecaller => initDispatch => {
    initDispatch({ type: 'LoadingTrue' });
    let file = [];
    if (images) {
        file = images.target.files;
    }
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('file[]', file[i]);
    }
    formData.append('brand_id', brand_id);
    formData.append('category_name', name);
    formData.append('category_name_ar', category_name_ar);
    formData.append('category_name_ku', category_name_ku);
    formData.append('category_id', category_id);
    axios.post(`${constant.host}category/update.php`,
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }
    ).then((res) => {
        console.log(res);
        // initDispatch({ type: 'LoadingFalse' });
        if (res.data.status == 'ok') {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    })
}