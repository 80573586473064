import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/provider";
import deleteaction from "../../context/action/cover/deleteaction";
import updateaction from "../../context/action/cover/updateaction";


const Editcovermodal = props => {
    const { initState, initDispatch, recaller, setrecaller } =
    useContext(GlobalContext);

    const [coverimage,setcoverimage]=useState();

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    const [error2, seterror2] = useState(false);
    const [error3, seterror3] = useState(false);
    const [error4, seterror4] = useState(false);
    const [error5, seterror5] = useState(false);
    const [error6, seterror6] = useState(false);
    const [error7, seterror7] = useState(false);
    const [error8, seterror8] = useState(false);
    const [error9, seterror9] = useState(false);

    const changeimag = (e) => {
        seterror9(false);
        setcoverimage(e);
      };
  return (
    <>
      {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl uppercase font-semibold">
                    Update Cover
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setshow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-wrap w-full py-5">
                  <div className="w-full md:w-1/3 px-3">
                      <label
                        className={
                          error
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 1
                      </label>
                      <input
                        className={
                          error
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt1}
                        onChange={(val) => props.settxt1(val.target.value)}
                        onFocus={() => seterror(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-3">
                      <label
                        className={
                          error1
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 1 Ar
                      </label>
                      <input
                        className={
                          error1
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt1ar}
                        onChange={(val) => props.settxt1ar(val.target.value)}
                        onFocus={() => seterror1(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-3">
                      <label
                        className={
                          error2
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 1 Ku
                      </label>
                      <input
                        className={
                          error2
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt1ku}
                        onChange={(val) => props.settxt1ku(val.target.value)}
                        onFocus={() => seterror2(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 pt-2 px-3">
                      <label
                        className={
                          error3
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 2
                      </label>
                      <input
                        className={
                          error3
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt2}
                        onChange={(val) => props.settxt2(val.target.value)}
                        onFocus={() => seterror3(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 pt-2 px-3">
                      <label
                        className={
                          error4
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 2 Ar
                      </label>
                      <input
                        className={
                          error4
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt2ar}
                        onChange={(val) => props.settxt2ar(val.target.value)}
                        onFocus={() => seterror4(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 pt-2 px-3">
                      <label
                        className={
                          error5
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 2 Ku
                      </label>
                      <input
                        className={
                          error5
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt2ku}
                        onChange={(val) => props.settxt2ku(val.target.value)}
                        onFocus={() => seterror5(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 pt-2 px-3">
                      <label
                        className={
                          error6
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 3
                      </label>
                      <input
                        className={
                          error6
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt3}
                        onChange={(val) => props.settxt3(val.target.value)}
                        onFocus={() => seterror6(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 pt-2 px-3">
                      <label
                        className={
                          error7
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 3 Ar
                      </label>
                      <input
                        className={
                          error7
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt3ar}
                        onChange={(val) => props.settxt3ar(val.target.value)}
                        onFocus={() => seterror7(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 pt-2 px-3">
                      <label
                        className={
                          error8
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        Cover Text 3 Ku
                      </label>
                      <input
                        className={
                          error8
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={props.txt3ku}
                        onChange={(val) => props.settxt3ku(val.target.value)}
                        onFocus={() => seterror8(false)}
                      />
                    </div>
                    <div className="w-full md:w-full pt-3 px-3">
                      <label
                        className={
                          error9
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="file_input"
                      >
                        Cover Image
                      </label>
                      <input
                        className={
                          error9
                            ? "block w-full mb-1 text-xs text-gray-900 border border-red-500 rounded-lg cursor-pointer py-3 px-4 bg-gray-50"
                            : "block w-full mb-1 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer py-3 px-4 bg-gray-50"
                        }
                        id="file_input"
                        type="file"
                        onChange={changeimag}
                        onFocus={() => seterror9(false)}
                      />
                      <p className="text-sm mb-4">
                      recommended image size is 1600px * 840px
                      </p>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (
                        window.confirm(
                          "ئاگاداری ئەنجامدانی ڕەشکردنەوە . دڵنیای ؟"
                        ) == true
                      ) {
                        deleteaction(props.cover_id)(recaller)(setrecaller)(initDispatch)
                      }
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                        if (props.txt1 == "" || !props.txt1) {
                            seterror(true);
                            return false;
                          }
                          if (props.txt1ar == "" || !props.txt1ar) {
                            seterror1(true);
                            return false;
                          }
                          if (props.txt1ku == "" || !props.txt1ku) {
                            seterror2(true);
                            return false;
                          }
                          if (props.txt2 == "" || !props.txt2) {
                            seterror3(true);
                            return false;
                          }
                          if (props.txt2ar == "" || !props.txt2ar) {
                            seterror4(true);
                            return false;
                          }
                          if (props.txt2ku == "" || !props.txt2ku) {
                            seterror5(true);
                            return false;
                          }
                          if (props.txt3 == "" || !props.txt3) {
                            seterror6(true);
                            return false;
                          }
                          if (props.txt3ar == "" || !props.txt3ar) {
                            seterror7(true);
                            return false;
                          }
                          if (props.txt3ku == "" || !props.txt3ku) {
                            seterror8(true);
                            return false;
                          }
                      if (
                        window.confirm(
                          "ئاگاداری ئەنجامدانی گۆڕانکاری . دڵنیای ؟"
                        ) == true
                      ) {
                        updateaction(props.txt1,props.txt1ar,props.txt1ku,props.txt2,props.txt2ar,props.txt2ku,props.txt3,props.txt3ar,props.txt3ku,props.cover_id,coverimage)(recaller)(setrecaller)(initDispatch)
                      }
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}

export default Editcovermodal