import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/offer/createaction";

const Addoffermodal = props => {
  const { recaller, setrecaller, initDispatch } = useContext(GlobalContext);


  const [offer_name,setoffer_name]=useState();
  const [offer_name_ar,setoffer_name_ar]=useState();
  const [offer_name_ku,setoffer_name_ku]=useState();
  const [offer_description,setoffer_description]=useState();
  const [offer_description_ar,setoffer_description_ar]=useState();
  const [offer_description_ku,setoffer_description_ku]=useState();
  const [offer_start,setoffer_start]=useState();
  const [offerimage,setofferimage]=useState();

  const [error,seterror]=useState(false);
  const [error1,seterror1]=useState(false);
  const [error2,seterror2]=useState(false);
  const [error3,seterror3]=useState(false);
  const [error4,seterror4]=useState(false);
  const [error5,seterror5]=useState(false);
  const [error6,seterror6]=useState(false);

  const changeimag = (e) => {
    seterror6(false);
    setofferimage(e);
  };
  return (
    <>
    {props.show ? (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl uppercase font-semibold">Add Offer</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => props.setshow(false)}
                >
                  <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                    X
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div className="flex flex-wrap w-full">
                  <div className="w-full md:w-1/3 px-3">
                    <label
                      className={
                        error
                          ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                          : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      }
                      for="grid-last-name"
                    >
                      Offer Name
                    </label>
                    <input
                      className={
                        error
                          ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      }
                      id="grid-last-name"
                      type="text"
                      placeholder=""
                      value={offer_name}
                      onChange={(val) => setoffer_name(val.target.value)}
                      onFocus={() => seterror(false)}
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3">
                    <label
                      className={
                        error1
                          ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                          : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      }
                      for="grid-last-name"
                    >
                      Offer Name Ar
                    </label>
                    <input
                      className={
                        error1
                          ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      }
                      id="grid-last-name"
                      type="text"
                      placeholder=""
                      value={offer_name_ar}
                      onChange={(val) => setoffer_name_ar(val.target.value)}
                      onFocus={() => seterror1(false)}
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3">
                    <label
                      className={
                        error2
                          ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                          : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      }
                      for="grid-last-name"
                    >
                      Offer Name Ku
                    </label>
                    <input
                      className={
                        error2
                          ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      }
                      id="grid-last-name"
                      type="text"
                      placeholder=""
                      value={offer_name_ku}
                      onChange={(val) => setoffer_name_ku(val.target.value)}
                      onFocus={() => seterror2(false)}
                    />
                  </div>
                  <div className="w-full md:w-1/3 pt-2 px-3">
                    <label
                      className={
                        error3
                          ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                          : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      }
                      for="grid-last-name"
                    >
                      Offer Description
                    </label>
                    <input
                      className={
                        error3
                          ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      }
                      id="grid-last-name"
                      type="text"
                      placeholder=""
                      value={offer_description}
                      onChange={(val) => setoffer_description(val.target.value)}
                      onFocus={() => seterror3(false)}
                    />
                  </div>
                  <div className="w-full md:w-1/3 pt-2 px-3">
                    <label
                      className={
                        error4
                          ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                          : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      }
                      for="grid-last-name"
                    >
                      Offer Description Ar
                    </label>
                    <input
                      className={
                        error4
                          ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      }
                      id="grid-last-name"
                      type="text"
                      placeholder=""
                      value={offer_description_ar}
                      onChange={(val) => setoffer_description_ar(val.target.value)}
                      onFocus={() => seterror4(false)}
                    />
                  </div>
                  <div className="w-full md:w-1/3 pt-2 px-3">
                    <label
                      className={
                        error5
                          ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                          : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      }
                      for="grid-last-name"
                    >
                      Offer Description Ku
                    </label>
                    <input
                      className={
                        error5
                          ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      }
                      id="grid-last-name"
                      type="text"
                      placeholder=""
                      value={offer_description_ku}
                      onChange={(val) => setoffer_description_ku(val.target.value)}
                      onFocus={() => seterror5(false)}
                    />
                  </div>
                  <div className="w-full md:w-full pt-3 px-3">
                    <label
                      className={
                        error6
                          ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                          : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      }
                      for="file_input"
                    >
                      Offer Image
                    </label>
                    <input
                      className={
                        error6
                          ? "block w-full mb-1 text-xs text-gray-900 border border-red-500 rounded-lg cursor-pointer py-3 px-4 bg-gray-50"
                          : "block w-full mb-1 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer py-3 px-4 bg-gray-50"
                      }
                      id="file_input"
                      type="file"
                      onChange={changeimag}
                      onFocus={() => seterror6(false)}
                    />
                    <p className="text-sm mb-4">
                      recommended image size is 400px * 650px
                    </p>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => props.setshow(false)}
                >
                  Close
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    if (offer_name == "" || !offer_name) {
                      seterror(true);
                      return false;
                    }
                    if (offer_name_ar == "" || !offer_name_ar) {
                      seterror1(true);
                      return false;
                    }
                    if (offer_name_ku == "" || !offer_name_ku) {
                      seterror2(true);
                      return false;
                    }
                    if (offer_description == "" || !offer_description) {
                      seterror3(true);
                      return false;
                    }
                    if (offer_description_ar == "" || !offer_description_ar) {
                      seterror4(true);
                      return false;
                    }
                    if (offer_description_ku == "" || !offer_description_ku) {
                      seterror5(true);
                      return false;
                    }
                    if (offerimage == "" || !offerimage) {
                      seterror6(true);
                      return false;
                    }
                    createaction(offer_name,offer_name_ar,offer_name_ku,offer_description,offer_description_ar,offer_description_ku,offerimage)(recaller)(setrecaller)(
                      initDispatch
                    );
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    ) : null}
  </>
  )
}

export default Addoffermodal