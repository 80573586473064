const Initreducer = (state, { type, payload }) => {
    switch (type) {
        case 'settoken':
            return { ...state, token: payload };
        case 'setinit':
            return { ...state, brand: payload.brand, active_brand: payload.active_brand, category: payload.category, active_category: payload.active_category, item: payload.item, user: payload.user, order: payload.order, cover: payload.cover, brand_number: payload.brand_number, active_brand_number: payload.active_brand_number, deactive_brand_number: payload.deactive_brand_number, category_number: payload.category_number, active_category_number: payload.active_category_number, deactive_category_number: payload.deactive_category_number, item_number: payload.item_number, active_item_number: payload.active_item_number, deactive_item_number: payload.deactive_item_number, user_number: payload.user_number, active_user_number: payload.active_user_number, deactive_user_number: payload.deactive_user_number, order_number: payload.order_number, accept_order_number: payload.accept_order_number, reject_order_number: payload.reject_order_number, wait_order_number: payload.wait_order_number, offer: payload.offer, admin: payload.admin, loading: false };
        case 'setsearchitem':
            return { ...state, item: payload };
        case 'setsearchuser':
            return { ...state, user: payload };
        case 'setsearchorder':
            return { ...state, order: payload };
        case 'setprofile':
            return { ...state, user_profile: payload };
        case 'loadingFalse':
            return { ...state, loading: false };
        case 'LoadingTrue':
            return { ...state, loading: true };
        case 'authcheckFalse':
            return { ...state, authcheck: false };
        case 'authcheckTrue':
            return { ...state, authcheck: true };
        default:
            return state;
    }
}

export default Initreducer;