import React from 'react';
import './css/loading.scss';

const Loading = () => {
  return (
    <div class="spinner">
            <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div style={{ color: '#00539f' }}>Loading...</div>
        </div>
  )
}

export default Loading